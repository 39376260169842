import Vue from "vue";
import TitleMixin from "@/mixins/title";
import SpinnerMixin from "@/mixins/spinner";
export default Vue.extend({
    mixins: [TitleMixin, SpinnerMixin],
    data() {
        return {
            title: this.$t("offices.edit.title"),
            id: this.$route.params.id,
            office: {},
            countries: [],
            currencies: [],
            regions: [],
            validationOptions: {
                rules: {
                    name: {
                        required: true,
                        rangeLength: [3, 50]
                    }
                }
            }
        };
    },
    methods: {
        onCountryCodeChanged(value) {
            if (value == null || value == "") {
                this.office.currencyCode = undefined;
                return;
            }
            const country = this.countries.find(country => country.code === value);
            if (country == undefined)
                return;
            const currency = this.currencies.find(currency => currency.code === country.currencyCode);
            if (currency == undefined)
                return;
            this.office.currencyCode = currency.code;
        },
        edit() {
            return this.$store.dispatch("office/edit", {
                id: this.id,
                office: this.office
            });
        },
        editSuccess() {
            this.$toast.showSuccessToast(this.$t("offices.edit.toastSuccessTitle"), this.$t("offices.edit.toastSuccessContent", {
                name: this.office.name
            }));
            return this.$router.push("/administration/offices");
        },
        cancel() {
            return this.$router.push("/administration/offices");
        }
    },
    async mounted() {
        this.showSpinner();
        try {
            this.countries = await this.$store.dispatch("country/getAll");
            this.currencies = await this.$store.dispatch("currency/getAll");
            this.office = await this.$store.dispatch("office/get", {
                id: this.id
            });
            this.title += ` ${this.office.name}`;
        }
        catch (errors) {
            this.$toast.showDangerToast(this.$t("shared.toastFailureTitle"), this.$t("shared.toastFailureContent", {
                error: errors[0].message
            }));
            await this.$router.push("/administration/offices");
        }
        finally {
            this.hideSpinner();
        }
    }
});
